import(/* webpackMode: "eager", webpackExports: ["CommonEventHandlers"] */ "/app/main/src/components/CommonEventHandlers.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material-nextjs@5.16.6_@emotion+cache@11.13.1_@mui+material@5.16.7_@emotion+react@11.13._ru3tr5f7xusiuntim4rojijdfu/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material@5.16.7_@emotion+react@11.13.3_@types+react@18.3.5_react@18.3.1__@emotion+styled_57h5rh5hqveuw32j5ebeup54em/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleOAuthProvider"] */ "/app/node_modules/.pnpm/@react-oauth+google@0.12.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@react-oauth/google/dist/index.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.20.0_next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../sub/ui/src/font/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./NotoSansKR-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./NotoSansKR-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./NotoSansKR-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"notoSansKR\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../sub/ui/src/font/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./NanumSquareRoundL.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./NanumSquareRoundR.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./NanumSquareRoundB.ttf\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"nanumSquareRound\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../sub/ui/src/font/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./SCoreDream5.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./SCoreDream7.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"scoreDream\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../sub/ui/src/font/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./y-spotlight.ttf\",\"weight\":\"500\",\"style\":\"normal\"}]}],\"variableName\":\"ySpotlight\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../sub/ui/src/font/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./NanumGothicCoding-Regular.ttf\",\"weight\":\"500\",\"style\":\"normal\"}]}],\"variableName\":\"nanumGothicCoding\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../sub/ui/src/font/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./HakgyoansimKkwabaegiOTFR.otf\",\"weight\":\"500\",\"style\":\"normal\"}]}],\"variableName\":\"HakgyoansimKkwabaegiOTFR\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LoginProfileLoader"] */ "/app/sub/ui/src/auth/LoginProfileLoader.tsx");
;
import(/* webpackMode: "eager" */ "/app/sub/ui/src/events/login-dialog-open/LoginDialogOpenEventHandler.tsx");
;
import(/* webpackMode: "eager" */ "/app/sub/ui/src/events/user-profile-open/UserProfileDialogOpenEventHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BasicConfigWebPropProvider"] */ "/app/sub/ui/src/provider/BasicConfigWebPropProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CoppiaPubProvider"] */ "/app/sub/ui/src/provider/CoppiaPubProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sub/ui/src/theme/theme.ts");
